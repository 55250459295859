<template>
  <v-data-table
    :headers="headers"
    :items="results.results"
    :options.sync="options"
    :server-items-length="results.count"
    v-if="results"
    :loading="isLoading"
    :footer-props="{itemsPerPageOptions: [5, 25, 50, 100]}"
    @update:options="options = options"
  >
    <template v-slot:item.id="{ item }">
      <router-link :to="{name: `${routerNamespace}.detail`, params: {id: item.id}}">
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:item.client.name="{ item }">
      <router-link :to="{name: `communities.detail`, params: {id: item.client.id}}">
        {{ item.client.name }}
      </router-link>
    </template>
    <template v-slot:item.enabled="{ item }">
      <v-simple-checkbox
        v-model="item.enabled"
        disabled
      ></v-simple-checkbox>
    </template>
    <!-- DISABLED -->
    <template v-if="false" v-slot:item.auto_billing="{ item }">
      <v-simple-checkbox
        v-model="item.auto_billing"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.goal_progress.total="{ item }">
      <template v-if="item.goal_progress && item.goal_progress.total">
        {{ item.goal_progress.total * 100 | truncate(5)}}%
      </template>
      <template v-else>
        N/A
      </template>
    </template>
    <template v-slot:top>
      <data-table-top
        :key="editedItem.id"
        :form-title="formTitle"
        :item="editedItem"
        :entity-name="entityName"
        :entity-name-plural="entityNamePlural"
        :can-create="canCreate"
        :can-edit="canEdit"
        :query.sync="filters.q"
        @save="save($event)"
        @close="close()"
        ref="top"
      >
        <template v-slot:modalForm="slotProps">
          <h3>General configuration</h3>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="slotProps.item.name"
                autofocus
                label="Name"
                :error-messages="itemErrors.fieldErrors.name || []"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
              <autocomplete-select
                apiPath="admin/groups"
                textField="name"
                :multiple="false"
                :readonly="slotProps.item.id != null"
                :filters="{client: createData.client}"
                v-model="slotProps.item.group"
                label="Group"
                :error-messages="itemErrors.fieldErrors.group || []"
              ></autocomplete-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-checkbox
                v-model="slotProps.item.enabled"
                label="Enabled"
              ></v-checkbox>
            </v-col>
            <!-- DISABLED -->
            <v-col
              v-if="false"
              cols="12"
              sm="4"
            >
              <v-checkbox
                v-model="slotProps.item.auto_billing"
                label="Automatic billing"
              ></v-checkbox>
            </v-col>
            <!-- DISABLED -->
            <v-col
              v-if="false"
              cols="12"
              sm="4"
            >
              <v-checkbox
                v-model="slotProps.item.needs_tuning"
                label="Needs tuning"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col
              cols="12"
              sm="2"
            >
              <v-text-field
                v-model="slotProps.item.connected_app"
                label="Connected app"
                :error-messages="itemErrors.fieldErrors.connected_app || []"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-text-field
                v-model="slotProps.item.vmocall_version"
                label="Version"
                :error-messages="itemErrors.fieldErrors.vmocall_version || []"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <list-field
                v-model="slotProps.item.report_emails"
                label="Report emails"
                :error-messages="itemErrors.fieldErrors.report_emails || []"
              ></list-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <list-field
                v-model="slotProps.item.kpis"
                label="KPIs"
                :error-messages="itemErrors.fieldErrors.kpis || []"
              ></list-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                outlined
                v-model="slotProps.item.internal_comment"
                label="Internal comment">
              </v-textarea>
            </v-col>
          </v-row>
          <h3 class="mt-4">Date and time configuration</h3>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <date-field label="Start date" v-model="slotProps.item.start" :error-messages="itemErrors.fieldErrors.start || []"></date-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <date-field label="End date" v-model="slotProps.item.end" :error-messages="itemErrors.fieldErrors.end || []"></date-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="slotProps.item.timezone"
                label="Timezone"
                :error-messages="itemErrors.fieldErrors.timezone || []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col
              cols="12"
              sm="4"
            >
              <time-field label="Worktime start" v-model="slotProps.item.worktime_start" :error-messages="itemErrors.fieldErrors.worktime_start || []"></time-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <time-field label="Worktime end" v-model="slotProps.item.worktime_end" :error-messages="itemErrors.fieldErrors.worktime_end || []"></time-field>
            </v-col>
          </v-row>
          <h3 class="mt-4">Transformations</h3>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <template v-if="slotProps.item.goal_progress && slotProps.item.goal_progress.total">
                <p class="mt-4">Current progress:</p>
                <ul class="mb-4">
                  <li v-for="k in Object.keys(slotProps.item.goal_progress)" :key="k">{{ k }}: {{ slotProps.item.goal_progress[k] * 100 | truncate(5) }}%</li>
                </ul>
              </template>
              <v-text-field
                v-model.number="slotProps.item.goal_agents"
                type="number"
                min="0"
                step="1"
                label="Goal: agents"
              ></v-text-field>
              <v-text-field
                v-model.number="slotProps.item.goal_hours"
                type="number"
                min="0"
                label="Goal: total hours (all transformations)"
              ></v-text-field>
              <v-checkbox
                v-model="slotProps.item.firstday_all_off"
                label="Passthrough on first day"
              ></v-checkbox>
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <div class="my-3">
                <v-icon
                  class="mr-2"
                  @click="slotProps.item.transformations.push({transformation: {id: null}, weight: null, params: null})"
                >
                  {{ $icons.mdiPlus }}
                </v-icon>
                <v-icon
                  v-if="slotProps.item.transformations.length > 2"
                  class="mr-2"
                  @click="slotProps.item.transformations.splice(-1, 1)"
                >
                  {{ $icons.mdiClose }}
                </v-icon>
              </div>
              <v-row class="mt-0" v-for="i in slotProps.item.transformations.length" :key="i">
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="slotProps.item.transformations[i - 1].transformation"
                    :items="transformations"
                    item-text="name"
                    item-value="id"
                    label="Transformation"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model.number="slotProps.item.transformations[i - 1].weight"
                    type="number"
                    min="0"
                    max="1"
                    step="0.01"
                    label="Weight"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="slotProps.item.transformations[i - 1].params"
                    label="Params"
                    placeholder="[1.1]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </data-table-top>
    </template>
    <template v-slot:item.actions="{ item }" v-if="canEdit(item) || canDestroy(item)">
      <v-icon
        small
        v-if="canEdit(item)"
        class="mr-2"
        @click="editItem(item)"
      >
        {{ $icons.mdiPencil }}
      </v-icon>
      <download-link
        :api-url="`admin/abtests/${item.id}/csv`"
        :filename="`${item.name}.csv`"
        :icon-attrs="{small: true}"
        class="mr-2"
        title="Download CSV report"></download-link>
      <download-link
        :api-url="`admin/abtests/${item.id}/csv?detailed=true`"
        :filename="`${item.name}-debug.csv`"
        :icon-attrs="{small: true}"
        download-icon="mdiFileCog"
        class="mr-2"
        title="Download audio sessions report"></download-link>
      <!-- DISABLED -->
      <a
        v-if="false"
        :href="globalGrafanaUrl + `d/pqDuLW8Mz/abtest?var-abtest_id=${item.id}`"
        title="View charts">
        <v-icon
          small
          class="mr-2"
        >
          {{ $icons.mdiChartBox }}
        </v-icon>
      </a>
    </template>
  </v-data-table>
</template>

<script>
import DataTable from './DataTable'
import DownloadLink from './DownloadLink'
import DateField from './DateField'
import ListField from './ListField'
import TimeField from './TimeField'
import AutocompleteSelect from '@/components/AutocompleteSelect'

import http from '@/http'

export default {
  mixins: [DataTable],
  components: {
    DateField,
    DownloadLink,
    ListField,
    TimeField,
    AutocompleteSelect,
  },
  data () {
    return {
      transformations: []
    }
  },
  async created () {
    this.transformations = (await http.get('admin/transformations', {params: {o: 'name'}})).data.results
  },
  methods: {

    parseItemForUpdate (item) {
      item.transformations.forEach(t => {
        t.transformation = t.transformation.id || t.transformation
        t.weight = t.weight || null
      })
      return item
    }
  }
}
</script>