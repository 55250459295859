<template>
  <v-data-table
    :headers="headers"
    :items="results.results"
    :options.sync="options"
    :server-items-length="results.count"
    v-if="results"
    :loading="isLoading"
    :footer-props="{itemsPerPageOptions: [5, 25, 50, 100]}"
    @update:options="options = options"
  >
    <template v-slot:item.id="{ item }">
      <router-link :to="{name: `${routerNamespace}.detail`, params: {id: item.id}}">
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:item.client.name="{ item }">
      <router-link :to="{name: `communities.detail`, params: {id: item.client.id}}">
        {{ item.client.name }}
      </router-link>
    </template>
    <template v-slot:top>
      <data-table-top
        :form-title="formTitle"
        :item="editedItem"
        :entity-name="entityName"
        :entity-name-plural="entityNamePlural"
        :can-create="canCreate"
        :can-edit="canEdit"
        :query.sync="filters.q"
        @save="save($event)"
        @close="close()"
        ref="top"
      >
        <template v-slot:modalForm="slotProps">
          <v-row >
            <v-col
              cols="4"
              sm="12"
            >
              <v-text-field
                v-model="slotProps.item.name"
                autofocus
                label="Name"
                :error-messages="itemErrors.fieldErrors.name || []"
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
              sm="12"
            >
              <v-text-field
                v-model="slotProps.item.email"
                type="email"
                label="Contact email"
                :error-messages="itemErrors.fieldErrors.email || []"
              ></v-text-field>
            </v-col>
            <!-- DISABLED -->
            <v-col
              v-if="false"
              cols="4"
              sm="12"
            >
              <v-text-field
                v-model="slotProps.item.email_billing"
                type="email"
                label="Billing email"
                :error-messages="itemErrors.fieldErrors.email_billing || []"
              ></v-text-field>
            </v-col>
            <!-- DISABLED -->
            <v-col
              v-if="false"
              cols="4"
              sm="12"
            >
              <v-text-field
                v-model="slotProps.item.vat_number"
                type="text"
                label="VAT Number"
                :error-messages="itemErrors.fieldErrors.vat_number || []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row >
            <v-col
              cols="6"
              sm="12"
            >
              <json-field
                v-model="slotProps.item.config"
                label="Config"
                :error-messages="itemErrors.fieldErrors.config || []"
              ></json-field>
            </v-col>
          </v-row>
          <h3 class="mt-4">Address</h3>
          <v-row >
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="slotProps.item.address.name"
                label="Recipient"
                placeholder="Company LTD."
                required
              ></v-text-field>
            </v-col>
          </v-row >
          <v-row >
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="slotProps.item.address.address"
                label="Street address"
                placeholder="108, La Canebière"
                required
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="slotProps.item.address.postal_code"
                label="Postal code"
                placeholder="13001"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="slotProps.item.address.locality"
                label="Locality"
                placeholder="Marseille"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="slotProps.item.address.country.code"
                label="Country code"
                placeholder="FR"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </data-table-top>
    </template>
    <template v-slot:item.actions="{ item }" v-if="canEdit(item) || canDestroy(item)">
      <v-icon
        small
        v-if="canEdit(item)"
        class="mr-2"
        @click="editItem(item)"
      >
        {{ $icons.mdiPencil }}
      </v-icon>
      <!-- DISABLED -->
      <a
        v-if="false"
        :href="globalGrafanaUrl + `d/q9Xe2WUGz/status?var-client_id=${item.id}`"
        title="View charts">
        <v-icon
          small
          class="mr-2"
        >
          {{ $icons.mdiChartBox }}
        </v-icon>
      </a>
    </template>
  </v-data-table>
</template>

<script>
import DataTable from './DataTable'
import JsonField from '@/components/JsonField'

export default {
  mixins: [DataTable],
  components: {
    JsonField
  }
}
</script>