<template>
  <v-data-table
    :headers="headers"
    :items="results.results"
    :options.sync="options"
    :server-items-length="results.count"
    v-if="results"
    :loading="isLoading"
    :footer-props="{itemsPerPageOptions: []}"
    @update:options="options = options"
  >
    <template v-slot:item.id="{ item }">
      <a :href="globalAuthUrl + `auth/admin/master/console/#/realms/master/users/${item.id}`">
        {{ item.id | truncate(13) }}
      </a>
    </template>
    <template v-slot:item.enabled="{ item }">
      <v-simple-checkbox
        v-model="item.enabled"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.email_verified="{ item }">
      <v-simple-checkbox
        v-model="item.email_verified"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:top>
      <data-table-top
        :headers="headers"
        :form-title="formTitle"
        :item="editedItem"
        :entity-name="entityName"
        :entity-name-plural="entityNamePlural"
        :can-create="canCreate"
        :can-edit="canEdit"
        :query.sync="filters.q"
        @save="save($event)"
        @close="close()"
        ref="top"
      >
        <template v-slot:modalForm="slotProps">
          <v-row v-if="slotProps.item.id">
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :value="slotProps.item.id"
                label="ID"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :value="slotProps.item.remote_id"
                label="Keycloak ID"
                readonly
              ></v-text-field>
              <a :href="globalAuthUrl + `auth/admin/master/console/#/realms/master/users`">
                Open on Keycloak
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <autocomplete-select
                v-if="!slotProps.item.id"
                apiPath="admin/groups"
                textField="name"
                :multiple="false"
                :filters="{client: createData.client}"
                v-model="slotProps.item.group"
                label="Group"
                :error-messages="itemErrors.fieldErrors.group || []"
              ></autocomplete-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="slotProps.item.email"
                :error-messages="itemErrors.fieldErrors.email || []"
                type="email"
                label="Email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-checkbox
                v-model="slotProps.item.email_verified"
                label="Verified email"
                :error-messages="itemErrors.fieldErrors.email_verified || []"
              ></v-checkbox>
              <p>Accounts with unverified email cannot log in.</p>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              v-if="slotProps.item.id"
            >
              <v-text-field
                v-model="slotProps.item.joined_date"
                label="Creation date"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="slotProps.item.password"
                type="password"
                label="Password (leave empty to keep current one)"
                :error-messages="itemErrors.fieldErrors.password || []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="slotProps.item.id">
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="slotProps.item.first_name"
                :error-messages="itemErrors.fieldErrors.first_name || []"
                type="text"
                label="First name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="slotProps.item.last_name"
                :error-messages="itemErrors.fieldErrors.last_name || []"
                type="text"
                label="Last name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            > 
              <h3>Permissions</h3>
              <template v-for="role in supportedRoles">
                <v-checkbox
                  :key="role.id"
                  v-model="slotProps.item.roles"
                  :value="role.id"
                  :label="`${role.id}: ${role.description}`"
                ></v-checkbox>
              </template>
            </v-col>
          </v-row>
        </template>
      </data-table-top>
    </template>
    <template v-slot:item.actions="{ item }" v-if="canEdit(item) || canDestroy(item)">
      <v-icon
        small
        v-if="canEdit(item)"
        class="mr-2"
        @click="editItem(item)"
      >
        {{ $icons.mdiPencil }}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import DataTable from './DataTable'
import AutocompleteSelect from '@/components/AutocompleteSelect'

const SUPPORTED_ROLES = [
  {id: "client-admin", "description": "Create other users, view billing, manage users, machines and tests"},
]
export default {
  mixins: [DataTable],
  components: {
    AutocompleteSelect
  },
  data () {
    return {
      supportedRoles: SUPPORTED_ROLES
    }
  },
  methods: {
    parseItemForUpdate (item) {
      let supportedRoleIds = this.supportedRoles.map(r => { return r.id })
      item.roles = item.roles.filter(r => {
        return supportedRoleIds.indexOf(r) > -1
      })

      return item
    }
  }
}
</script>